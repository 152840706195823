import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Config from '../Config.json';
import authHeader from '../authHeader';


class GreenFieldService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            FlatId: null
        };

    }

    getOrderRequests(franchiseId){
        return axios.get(`${Config.apiUrl}/OrderRequest/GetData?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    getPermissionDetails(societyId) {
        return axios.get(`${Config.apiUrl}/Permission/GetPermissionDetails?societyId=${societyId}`, { headers: authHeader() }).then(res => res.data);
    }
    getUsers(franchiseId) {
        return axios.get(`${Config.apiUrl}/Permission/GetUserDetails?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    getUserName(userId) {
        return axios.get(`${Config.apiUrl}/Profile/GetUserName?userId=${userId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetUnitsData(){
        return axios.get(`${Config.apiUrl}/Units/GetUnits`, { headers: authHeader() }).then(res => res.data);
    }
    GetCategoryData(){
        return axios.get(`${Config.apiUrl}/Category/GetCategory`, { headers: authHeader() }).then(res => res.data);
    }
    GetProductsData(){
        return axios.get(`${Config.apiUrl}/Products/GetProducts`, { headers: authHeader() }).then(res => res.data);
    }
    GetAllProductsData(){
        return axios.get(`${Config.apiUrl}/Products/GetAllProducts`, { headers: authHeader() }).then(res => res.data);
    }
    GetExpiredProducts(franchiseId){
        return axios.get(`${Config.apiUrl}/Dashboard/GetExpiredProduct?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetCustomerData(franchiseId){
        return axios.get(`${Config.apiUrl}/Customer/GetCustomers?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }

    GetFarmerComplaintsData(franchiseId){
        return axios.get(`${Config.apiUrl}/FarmerComplaint/GetData?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }

    GetFranchiseComplaintsData(franchiseId){
        return axios.get(`${Config.apiUrl}/FranchiseComplaint/GetData?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetMostTransFranchises(){
      return  axios.get(`${Config.apiUrl}/Dashboard/GetFranchiseTransAmount`, { headers: authHeader() }).then(res=>res.data);
    }
    GetMostTransProducts(){
        return axios.get(`${Config.apiUrl}/Dashboard/GetProductsWithTransAmount`, { headers: authHeader() }).then(res=>res.data);
    }
    GetCurrentMonthSales(){
        return axios.get(`${Config.apiUrl}/Dashboard/CurrentMonthSales`, { headers: authHeader() }).then(res=>res.data);
    }
    GetAdminTotalSales(){
        return axios.get(`${Config.apiUrl}/Dashboard/AdminTotalSales`, { headers: authHeader() }).then(res=>res.data);
    }
    GetFranchiseDashboardData(franchiseId){
        return axios.get(`${Config.apiUrl}/Dashboard/FranchiseDashboardData?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res=>res.data);
    }
    GetCustomerDetail(customerId){
        return axios.get(`${Config.apiUrl}/Customer/GetCustomerOnId?Id=${customerId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetStocksOnProduct(ProductId){
        return axios.get(`${Config.apiUrl}/Stocks/GetStockOnProduct?ProductId=${ProductId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetStockDetails(franchiseId){
        return axios.get(`${Config.apiUrl}/Stocks/GetAddStockDetails`, { headers: authHeader() }).then(res => res.data);
    }
    GetFranchiseStockDetails(franchiseId){
        return axios.get(`${Config.apiUrl}/Stocks/FranchiseStockDetail?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetFranchiseData(){
        return axios.get(`${Config.apiUrl}/Franchise/GetData`, { headers: authHeader() }).then(res => res.data);
    }
    GetDistibutedStockBatchWise(productId,batchNo){
        return axios.get(`${Config.apiUrl}/Stocks/GetDistributedQtyBatchWise?productId=${productId}&batchNo=${batchNo}`, { headers: authHeader() }).then(res => res.data);
    }
    GetFranchiseDataOnId(FranchiseId){
        return axios.get(`${Config.apiUrl}/Franchise/GetDataOnId?FranchiseId=${FranchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetAvailableStock(productId,batchNo){
        return axios.get(`${Config.apiUrl}/Stocks/AvilableStock?productId=${productId}&batchNo=${batchNo}`, { headers: authHeader() }).then(res => res.data);
    }
    GetFranchiseAvailableStock(productId,batchNo,franchiseId){
        return axios.get(`${Config.apiUrl}/Franchise/FranchiseAvilableStock?productId=${productId}&batchNo=${batchNo}&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetDistributedStock(franchiseId){
        return axios.get(`${Config.apiUrl}/Stocks/GetStock?FranchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetIsReceivedStock(franchiseId,isReceivedStock){
        return axios.get(`${Config.apiUrl}/Stocks/IsReceivedStock?FranchiseId=${franchiseId}&isReceivedStock=${isReceivedStock}`, { headers: authHeader() }).then(res => res.data);
    }
    GetFranchiseStock(franchiseId){
        return axios.get(`${Config.apiUrl}/Stocks/FranchiseStockDetail?FranchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetReturnStockDetails(franchiseId){
        return axios.get(`${Config.apiUrl}/Stocks/GetReturnStockData?FranchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);

    }
    GetBatchNo(productId){
        return axios.get(`${Config.apiUrl}/Stocks/GetBatchNo?productId=${productId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetDistributedBatchNo(productId,franchiseId){
        return axios.get(`${Config.apiUrl}/Stocks/GetDistributedBatchNo?productId=${productId}&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetExpiryDate(productId,batchNo){
        return axios.get(`${Config.apiUrl}/Stocks/GetExpiryDate?productId=${productId}&batchNo=${batchNo}`, { headers: authHeader() }).then(res => res.data);
    }
    GetInvoiceList(franchiseId,fyDate,toDate){
        return axios.get(`${Config.apiUrl}/Invoice/GetInvoiceList?franchiseId=${franchiseId}&&fromDate=${fyDate}&&toDate=${toDate}`, { headers: authHeader() }).then(res => res.data);
    }
    GetInvoiceListOnCustomerId(customerId){
        return axios.get(`${Config.apiUrl}/SalesReturn/GetInvoiceOnCustomer?customerId=${customerId}`, { headers: authHeader() }).then(res => res.data);
    }

    GetInvoice(invNo,franchiseId){
        return axios.get(`${Config.apiUrl}/Invoice/InvoiceDetail?invNo=${invNo}&&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }

    GetSaleProductDetails(franchiseId){
        return axios.get(`${Config.apiUrl}/Invoice/GetSaleDetails?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetLogs(){
        return axios.get(`${Config.apiUrl}/Miscellaneous/GetData`, { headers: authHeader() }).then(res =>res.data);
    }

    GetFranchiesesDistributedStock(){
        return axios.get(`${Config.apiUrl}/Stocks/GetDistributedStock`, { headers: authHeader() }).then(res => res.data);
    }
    
    GetFranchiseProductsData(franchiseId,isActive){
        return axios.get(`${Config.apiUrl}/Products/GetFranchiseProducts?franchiseId=${franchiseId}&&isActive=${isActive}`, { headers: authHeader() }).then(res => res.data);
    }
    GetReturnStockOnId(franchiseId)
    {
        return axios.get(`${Config.apiUrl}/Stocks/GetReturnStock?FranchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetAllInvoiceOfCustomer(customerId)
    {
        return axios.get(`${Config.apiUrl}/Report/GetAllInvoiceOfCustomer?customerId=${customerId}`, { headers: authHeader() }).then(res => res.data);
    }
   
    GetAmounts(franchiseId){
        return axios.get(`${Config.apiUrl}/Accounts/GetAccountAmounts?FranchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);

    }
    GetPaymentDetails(franchiseId){
        return axios.get(`${Config.apiUrl}/Payment/GetPaymentData?FranchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetAllAccount(franchiseId){
        return axios.get(`${Config.apiUrl}/Accounts/GetAllAccount?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetReturnInvoiceList(franchiseId){
        return axios.get(`${Config.apiUrl}/SalesReturn/GetReturnInvoiceList?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetReturnInvoice(invNo,franchiseId){
        return axios.get(`${Config.apiUrl}/SalesReturn/ReturnInvoiceDetail?returnInvNo=${invNo}&&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetOutstandingData(fyDate,toDate){
        return axios.get(`${Config.apiUrl}/Report/OutstandingReport?fromDate=${fyDate}&&toDate=${toDate}`, { headers: authHeader() }).then(res => res.data);
    }
    GetProductWiseReportData(fyDate,toDate,franchiseId)
    {
        return axios.get(`${Config.apiUrl}/Report/ProductWiseReport?fromDate=${fyDate}&&toDate=${toDate}&&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetHsnWiseReportData(fyDate,toDate,franchiseId)
    {
        return axios.get(`${Config.apiUrl}/Report/HsnWiseReport?fromDate=${fyDate}&&toDate=${toDate}&&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetStockReportData(fyDate,toDate,franchiseId)
    {
        return axios.get(`${Config.apiUrl}/Report/StockReport?fromDate=${fyDate}&&toDate=${toDate}&&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetInvoiceReportData(fyDate,toDate,franchiseId)
    {
        return axios.get(`${Config.apiUrl}/Report/InvoiceReport?fromDate=${fyDate}&&toDate=${toDate}&&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetFranchiseStockValueData(fyDate,toDate)
    {
        return axios.get(`${Config.apiUrl}/Report/franchiseStockValueReport?fromDate=${fyDate}&&toDate=${toDate}`, { headers: authHeader() }).then(res => res.data);
    }
    GetReturnProductWiseReportData(fyDate,toDate,franchiseId){
        return axios.get(`${Config.apiUrl}/Report/RetProductWiseReport?fromDate=${fyDate}&&toDate=${toDate}&&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetReturnInvoiceReportData(fyDate,toDate,franchiseId)
    {
        return axios.get(`${Config.apiUrl}/Report/ReturnInvoiceReport?fromDate=${fyDate}&&toDate=${toDate}&&franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetMaxOrderNo(franchiseId){
        return axios.get(`${Config.apiUrl}/OrderRequest/GetOrderNo?FranchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    GetGkmProductQty(fyDate,toDate,productId)
    {
        return axios.get(`${Config.apiUrl}/Report/GkmWiseQtyDetails?fromDate=${fyDate}&&toDate=${toDate}&&productId=${productId}`, { headers: authHeader() }).then(res => res.data);
    }

    GetLockScreenData()
    {
        return axios.get(`${Config.apiUrl}/LockScreen/GetData`, { headers: authHeader() }).then(res => res.data);
    }

    GetLockScreenRelevantData()
    {
        return axios.get(`${Config.apiUrl}/LockScreen/GetLockScreenRelevantData`, { headers: authHeader() }).then(res => res.data);
    }


    LockUnlockScreen(data)
    {
        return axios.post(`${Config.apiUrl}/LockScreen/Insert`,data, { headers: authHeader() }).then(res => res.data);
    }
    GetFranchiseLockScreenStatus(franchiseId)
    {
        return axios.get(`${Config.apiUrl}/LockScreen/GetFranchiseLockScreenStatus?franchiseId=${franchiseId}`, { headers: authHeader() }).then(res => res.data);
    }
    
    
  
    
}
export default GreenFieldService;
 