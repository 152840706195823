import html2pdf from 'html2pdf.js';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import moment, { invalid } from 'moment';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import '../Franchise/InvoiceReport.css'
import GreenFieldService from '../../services/greenFieldService';
import { useParams } from 'react-router';
import logo from "../../image/greenfield new logo.png";
import { useLocation } from "react-router-dom";


var converter = require('number-to-words');
var franchiseDetails;
var customerDetail;
var invoiceDetail;
var taxableValueSum;
var centerAmountSum;
var stateAmountSum;
var totalTaxAmountSum;
var franchiseId;
var splitProductDetails;
var invoicesData = [];

const HtmlToPdfConverter = () => {

    const location = useLocation();
    franchiseId = location.state.franchiseId;


    const [BillDate, setBillDate] = useState();
    // const { customerId, invNo, franchiseId } = useParams();
    var [spinnerVisible, setSpinnerVisible] = useState(false);
    var converter = require('number-to-words');
    const greenFieldService = new GreenFieldService();
    const [franchiseData, setFranchiseData] = useState([]);
    franchiseDetails = franchiseData;
    const [customerData, setCustomerData] = useState([]);
    customerDetail = customerData;
    const [invoiceData, setInvoiceData] = useState([]);
    invoiceDetail = invoiceData;




    useEffect(() => {
        greenFieldService.GetFranchiseDataOnId(franchiseId).then(data => {
            data.forEach(a => a.franchiseAddress = `${a.address},${a.state}/${a.district}`);
            setFranchiseData(data);
        });

        // greenFieldService.GetCustomerDetail(1).then(data => {
        //     data.forEach(a => {
        //         a.isKisanCardValue = a.isKisanCard ? "Yes" : "No";
        //     })
        //     setCustomerData(data);
        // });

        greenFieldService.GetInvoice(-1, franchiseId).then(data => {
            // data[0].invoiceReportDetailsInfo.forEach(a => {
            //     a.taxableRate = parseFloat(a.taxableRate);
            //     a.totalTaxAmount = parseFloat(a.totalTaxAmount);
            //     a.stateAmount = parseFloat(a.stateAmount);
            //     a.centerAmount = parseFloat(a.centerAmount);

            // })
            data.forEach(k => {
                k.taxableValueSum = (k.invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.taxableRate), 0));
                k.centerAmountSum = (k.invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.centerAmount), 0));
                k.stateAmountSum = (k.invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.stateAmount), 0));
                k.totalTaxAmountSum = (k.invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.totalTaxAmount), 0));
                k.amountInWord = converter.toWords(Math.round(k?.dueAmount))
                k.amountInWord = k.amountInWord.toUpperCase();
                // k.invoiceReportDetailsInfo=splitArray(k.invoiceReportDetailsInfo,6);
                
            })


            let srNo = 1;
            data[0].hsnReportDetailsInfo.forEach(a => {
                a.serialNo = srNo;
                srNo++;
            })



            setInvoiceData(data);


        });



    }, []);

    const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: a5 landscape;
    margin: 5mm 10mm 5mm 10mm  !important;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .PageBreak{
        display: block;
        page-break-after: always;
        page-break-before: always;
        }

    .table-container {
        display: block;
        page-break-inside: avoid; /* Prevent the table from being broken inside */
        }

    html, body {
      width: 210mm;
      height: 148mm;

    }
  }
`;
    const htmlContentRef = useRef(null);

    const convertToPdf = () => {
        const inputElement = htmlContentRef.current;

        // Configuration for the PDF generation
        const pdfOptions = {
            // margin: 1,
            filename: `${franchiseData[0].name}`,
            //   image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
              jsPDF: { unit: 'mm', format: 'a5', orientation: 'landscape' },
        };

        // Generate PDF
        html2pdf(inputElement, pdfOptions);
    };

    return (

        <div>
            <button onClick={convertToPdf}>Download</button>

            {/* Your HTML content to be converted */}
            <div ref={htmlContentRef} >
                <div className="InvoicePrint">
                    {invoiceDetail.map((data, index) =>
                        <div>

                            <div class="Table">
                                <div class="Table">
                                    <table>
                                        <tbody>


                                            <tr>
                                                <th class="text-center" rowspan="4" style={{ width: '10%' }}>
                                                    <img src={logo} alt="logo" style={{ width: '100px' }} />

                                                </th>
                                                <th class="text-center" rowspan="3" style={{ width: '600px' }}>
                                                    <b style={{ height: '50px' }} > {franchiseDetails[0]?.name} </b><br />
                                                    <b style={{ marginTop: 'auto' }}> {franchiseDetails[0]?.franchiseAddress} </b><br />
                                                    <b> Contact : {franchiseDetails[0]?.mobile1} Email :{franchiseDetails[0]?.email} </b>
                                                    <table>
                                                        <tbody>
                                                            <td style={{ width: '50%' }}>
                                                                Place Of supply : Maharashtra ,Code: 27
                                                            </td>
                                                            <td style={{ width: '50%' }}>
                                                                GST :{franchiseDetails[0]?.gstno}
                                                            </td>
                                                        </tbody>
                                                    </table>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <table>
                                <tbody>
                                    <tr colspan="3">
                                        <td class="text-center">{franchiseDetails[0]?.fertilizerLicense}</td>
                                        <td class="text-center">{franchiseDetails[0]?.seedLicense}</td>
                                        <td class="text-center">{franchiseDetails[0]?.insectisideLicense}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table>
                                <tbody>
                                    <tr colspan="2">
                                        <td>
                                            Buyer &nbsp; &nbsp;&nbsp;: {invoiceDetail[index].customerDetailsInfo[0]?.name} &nbsp;&nbsp;  Mobile:{invoiceDetail[index].customerDetailsInfo[0]?.mobile1} <br />
                                            Address : {invoiceDetail[index].customerDetailsInfo[0]?.town},{invoiceDetail[index].customerDetailsInfo[0]?.district},{invoiceDetail[index].customerDetailsInfo[0]?.state} <br />

                                        </td>
                                        <td>
                                            Invoice Number&nbsp;: {invoiceDetail[index]?.invName} <br />
                                            Invoice Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {invoiceDetail[index]?.invDateFormat} <br />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <table >
                                <thead>
                                    <tr>
                                        <th style={{ width: '5%' }} class="text-center">Sr No</th>
                                        <th style={{ width: '15%' }} class="text-center">Description</th>
                                        <th style={{ width: '7%' }} class="text-center">HSN</th>
                                        <th style={{ width: '7%' }} class="text-center">GST %</th>
                                        <th style={{ width: '7%' }} class="text-center">Batch</th>
                                        <th style={{ width: '7%' }} class="text-center">Exp.Date</th>
                                        <th style={{ width: '7%' }} class="text-center">Gross Rate</th>
                                        <th style={{ width: '7%' }} class="text-center">Qty</th>
                                        <th style={{ width: '7%' }} class="text-center">Taxable Rate</th>
                                        <th style={{ width: '7%' }} class="text-center">Discount</th>
                                        <th style={{ width: '7%' }} class="text-center">Taxable Amount</th>
                                        {/* <th style={{ width: '7%' }} class="text-center">CGST</th>
                                    <th style={{ width: '7%' }} class="text-center">SGST</th> */}
                                        {/* <th style={{ width: '10%' }} class="text-center">Total</th> */}
                                    </tr>
                                </thead>

                               
                                <tbody>
                                {invoiceDetail[index].invoiceReportDetailsInfo.map((item) => (

                                        <tr >
                                        <td style={{ textAlign: 'right' }}>{ item?.srNo}</td>
                                        <td>{ item?.productName}</td>
                                        <td style={{ textAlign: 'right' }}>{ item?.hsnCode}</td>
                                        <td style={{ textAlign: 'right' }}>{ item?.gstRate}</td>
                                        <td style={{ textAlign: 'right' }}>{ item?.batchNo}</td>
                                        <td style={{ textAlign: 'right' }}>{new Date( item?.expiryDate).toLocaleDateString('en-En',{ year: 'numeric', month: 'short' })}</td>
                                        <td style={{ textAlign: 'right' }}>{ item?.frp.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{ item?.quantity}</td>
                                        <td style={{ textAlign: 'right' }}>{ item?.rate.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{ item?.discount.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{ item?.taxableRate.toFixed(2)}</td>
                                        {/* <td style={{ textAlign: 'right' }}>{item?.centerAmount.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.stateAmount.toFixed(2)}</td> */}
                                        {/* <td style={{ textAlign: 'right' }}>{item?.total.toFixed(2)}</td> */}
                                        

                                    </tr>
                                
                                    
                                    ))
                                }
                                    
                                </tbody>
                             



                            </table>

                            <table >
                                <tbody>
                                    <tr>
                                    <th class="text-center" colspan="5" rowspan="2" style={{ width: '4%' }}>Sr No</th>
                                        <th class="text-center" colspan="2" rowspan="2" style={{ width: '15%' }}>Taxable Value</th>
                                        <th colspan="2" class="text-center" style={{ width: '14%' }}>Central Tax</th>
                                        <th colspan="2" class="text-center" style={{ width: '14%' }}>State Tax</th>
                                        <th class="text-center" rowspan="2" style={{ width: '14%' }}>Total Tax Amount</th>

                                        <th class="text-center" rowspan="4" style={{ width: '14%' }}>
                                            <tr style={{ textAlign: 'right' }}>Taxable Total </tr>
                                            <tr style={{ textAlign: 'right' }}>CGST </tr>
                                            <tr style={{ textAlign: 'right' }}>SGST </tr>
                                            <tr style={{ textAlign: 'right' }}>SubTotal </tr>
                                            <tr style={{ textAlign: 'right' }}>Round Off </tr>
                                            <tr style={{ textAlign: 'right' }}>Hamali </tr>
                                            <tr style={{ textAlign: 'right' }}>Transport </tr>

                                        </th>
                                        <th class="text-center" rowspan="4" style={{ width: '7%' }}>
                                            <tr style={{ textAlign: 'right', display: 'block' }}>{invoiceDetail[index]?.taxableValueSum.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right', display: 'block' }}>{invoiceDetail[index]?.centerAmountSum.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right', display: 'block' }}>{invoiceDetail[index]?.stateAmountSum.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right', display: 'block' }}>{invoiceDetail[index]?.grandTotal.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right', display: 'block' }}>{invoiceDetail[index]?.roundUp.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right', display: 'block' }}>{invoiceDetail[index]?.hamali.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right', display: 'block' }}>{invoiceDetail[index]?.transport.toFixed(2)}</tr>


                                        </th>


                                    </tr>

                                    <tr>
                                        <td colspan="1" style={{ width: '7%' }} class="text-center">Rate</td>
                                        <td colspan="1" style={{ width: '7%' }} class="text-center">Amount</td>
                                        <td colspan="1" style={{ width: '7%' }} class="text-center">Rate</td>
                                        <td colspan="1" style={{ width: '7%' }} class="text-center">Amount</td>

                                    </tr>



                                    {invoiceDetail[index].hsnReportDetailsInfo.map((item) => (

                                        <tr key={item}>
                                            <td colspan="5" style={{ textAlign: 'right' }}>{item.serialNo}</td>
                                            <td colspan="2" style={{ textAlign: 'right' }}>{item?.taxableRate.toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.cgst}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.centerAmount.toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.sgst}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.stateAmount.toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.totalTaxAmount.toFixed(2)}</td>

                                        </tr>

                                    ))}


                                    <tr>
                                        <th colspan="5">Total</th>
                                        <th colspan="2" style={{ textAlign: 'right' }}>{invoiceDetail[index].taxableValueSum.toFixed(2)}</th>
                                        <th style={{ textAlign: 'right' }}></th>
                                        <th style={{ textAlign: 'right' }}>{invoiceDetail[index].centerAmountSum.toFixed(2)}</th>
                                        <th style={{ textAlign: 'right' }}></th>
                                        <th style={{ textAlign: 'right' }}>{invoiceDetail[index].stateAmountSum.toFixed(2)}</th>
                                        <th style={{ textAlign: 'right' }}>{invoiceDetail[index].totalTaxAmountSum.toFixed(2)}</th>
                                     
                                    </tr>
                                </tbody>
                            </table>

                            <table>
                                <tbody>
                                    <tr colspan="3">
                                        <td style={{ width: '62%' }}><b>Amount In Word :{invoiceDetail[index].amountInWord} RUPPES ONLY </b></td>
                                        <td style={{ width: '14%' }}>Including Tax Total</td>
                                        <td style={{ width: '7%', textAlign: 'right' }}><b>{invoiceDetail[index]?.dueAmount.toFixed(2)}</b></td>

                                    </tr>
                                </tbody>
                            </table>

                            <table>
                                <tbody>
                                    <tr>
                                        <td >Here Declare that this invoice shows the actual price of the goods described and that all particulars are true and correct</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table >
                                <tbody>
                                    <tr colspan="3">
                                        <td rowspan="3" >
                                            &nbsp;<br />
                                            &nbsp;<br />
                                            <b style={{ textAlign: 'right' }}>Receiver Sign</b>
                                        </td>
                                        <td style={{ width: '40%' }} >
                                            Our Bank Name &nbsp;: &nbsp; <b>ICICI BANK </b> <br />
                                            Account Number :&nbsp;&nbsp; <b>645205500482 </b><br />
                                            Branch & IFSC &nbsp;&nbsp; :&nbsp; <b>PHALTAN & ICIC0006452</b>
                                        </td>

                                        <td>
                                            &nbsp;<br />
                                            &nbsp;<br />
                                            <b style={{ textAlign: 'end' }}>For {franchiseDetails[0]?.name}</b>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            {index < invoiceDetail.length - 1 && <div style={{pageBreakBefore:'always'}} />}

                        </div>
                    )}
                </div>
            </div>
            </div>
                    );
};

                    export default HtmlToPdfConverter;
